








import TimezonePicker from 'piramis-base-components/src/components/TimezonePicker/TimezonePicker.vue'
import { SetTimezonePayload } from 'piramis-base-components/src/components/TimezonePicker/types'

import { Component } from 'vue-property-decorator';
import Vue from "vue";

@Component({
  components: {
    TimezonePicker
  }
})
export default class TimezoneSelect extends Vue {
  get chat() {
    return this.$store.state.chatState.chat
  }

  setTimezone(payload: SetTimezonePayload) {
    this.$store.dispatch('saveChatConfigRequest', { timezone: payload.timezone })
      .then(res => {
        payload.closeModal()
      })
      .finally(() => {
        payload.closeLoader()
      })
  }
}
